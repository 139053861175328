<template>
    <base-index
        title="View Drivers Weekly Salary"
        :entities="entities"
        :columns="columns"
        @onPageChanged="onPageChanged"
        :config="config">

        <template #controls>
            <base-button
                @click="downloadAll"
                size="sm"
                type="neutral">Download</base-button>
        </template>

        <template #filters>
            <b-col sm="3" v-if="$store.getters['auth/isSuper']">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Branch <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getBranches"
                                placeholder="Pick Branch"
                                v-model="filter.branch_id"
                            >
                                <el-option v-for="option in branches"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.name"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Operation <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getReports"
                                placeholder="Pick Operation"
                                v-model="filter.operation_id"
                            >
                                <el-option v-for="option in operations"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="`${ option.name } (${ option.branch.name })`"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Driver</label>
                        <div class="has-label">
                            <el-select
                                filterable
                                remote
                                clearable
                                @change="getReports"
                                :remote-method="getDrivers"
                                placeholder="Search Driver By Name Or Phone"
                                v-model="filter.driver_id"
                            >
                                <el-option v-for="option in drivers"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.code"
                                        :key="option.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <label class="form-control-label"> Start Date</label>
                <base-input addon-left-icon="ni ni-calendar-grid-58" class="mt-0">
                    <flat-picker
                            @change="getReports"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="filter.start_date">
                    </flat-picker>
                </base-input>
            </b-col>
            <b-col sm="3">
                <label class="form-control-label"> End Date</label>
                <base-input addon-left-icon="ni ni-calendar-grid-58" class="mt-0">
                    <flat-picker
                            @change="getReports"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="filter.end_date">
                    </flat-picker>
                </base-input>
            </b-col>
        </template>

        <template #cell_total_delivery_fee_usd="{ entity }">
            {{ entity.total_delivery_fee_usd ? entity.total_delivery_fee_usd.toLocaleString() : 0 }}
        </template>

        <template #cell_total_delivery_fee_riel="{ entity }">
            {{ entity.total_delivery_fee_riel ? entity.total_delivery_fee_riel.toLocaleString() : 0 }}
        </template>

        <template #actions="{ entity }">
            <el-tooltip placement="top" :content="`download_salary_invoice`">
                <base-button @click="downloadSalaryInvoice(entity)" size="sm" type="info"><i class="fa fa-edit"></i></base-button>
            </el-tooltip>
        </template>

        <template #others>
            <modal-input-data @onDownload="download" @downloadSingle="downloadSingle" ref="modalInputData"></modal-input-data>
        </template>

    </base-index>
</template>
<script>
import BaseIndex from '@components/Custom/BaseIndex';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
    Select,
    Option,
} from 'element-ui'
import store from './store';
import ReportRequest from '@requests/report_request';
export default {
    data() {
        return  {
            penalty: [],
            bonus: [],
            filter: {
                operation_id: '',
                driver_id: '',
                branch_id: '',
                start_date: new Date(),
                end_date: new Date(),
                page: 1
            },
            columns: [
                {
                    label: 'Code',
                    key: 'code'
                },
                {
                    label: 'Total Normal',
                    key: 'total_normal'
                },
                {
                    label: 'Total Taxi',
                    key: 'total_taxi'
                },
                {
                    label: 'Total Bus',
                    key: 'total_bus'
                },
                {
                    label: 'Total Pickup',
                    key: 'total_pickup'
                },
                {
                    label: 'Total Delivery ($)',
                    key: 'total_delivery_fee_usd'
                },
                {
                    label: 'Total Delivery (៛)',
                    key: 'total_delivery_fee_riel'
                },
            ],
            config: {
                showAddNew: false,
                hasBasicActions: false
            },
            selectedEntity: null
        };
    },
    methods: {
        downloadAll() {
            ReportRequest.downloadDriversSalary({
                ...this.filter,
                bonus: this.bonus,
                penalty: this.penalty
            });
        },
        download(meta) {
            this.penalty[this.selectedEntity.code] = Object.values(meta.outcome).reduce((acc, row) => {
                acc += row;
                return acc;
            }, 0);

             this.bonus[this.selectedEntity.code] = Object.values(meta.income).reduce((acc, row) => {
                acc += row;
                return acc;
            }, 0);
        },
        downloadSingle(meta) {
            store.dispatch('downloadSalaryInvoice', {
                entity: this.selectedEntity,
                meta: {
                    meta,
                    start_date: this.filter.start_date,
                    end_date: this.filter.end_date
                }
            });
        },
        downloadSalaryInvoice(entity) {
            this.selectedEntity = entity;
            this.$refs.modalInputData.show();
        },
        settle(entity) {
            this.popupConfirm('Are you sure?', null, () => {
                store.dispatch('settle', {
                    entity,
                    date: this.filter.date
                })
                .then(() => {
                    this.toastSuccess('Paid Customer');
                    this.getReports();
                });
            });
        },
        getDrivers(search) {
            store.dispatch('getDrivers', {
                search
            });
        },
        getReports() {
            store.dispatch('index', this.filter);
        },
        getBranches() {
            store.dispatch('getBranches')
        },
        onPageChanged(page) {
            this.filter.page = page;
            this.getReports();
        }
    },
    mounted() {
        this.getReports();
        this.getBranches();
        store.dispatch('getOperations');
    },
    computed: {
        entities() {
            return store.getters['entities'];
        },
        operations() {
            return store.getters['operations'].filter(operation => {
                return this.filter.branch_id ? operation.branch_id === this.filter.branch_id : true;
            });
        },
        branches() {
            return store.getters['branches'];
        },
        drivers() {
            return store.getters['drivers'];
        },
    },
    watch: {
        'filter.start_date': function(val) {
            if (val) {
                this.getReports();
            }
        },
        'filter.end_date': function(val) {
            if (val) {
                this.getReports();
            }
        }
    },
    components: {
        BaseIndex,
        [Select.name]: Select,
        [Option.name]: Option,
        flatPicker,
        ModalInputData: () => import('./ModalInputData')
    }
}
</script>