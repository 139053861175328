import DriverRequest from '@requests/driver_request';
import OperationRequest from '@requests/operation_request';
import SalaryRequest from '@requests/salary_request';
import BranchRequest from '@requests/branch_request';

export default {
    downloadSalaryInvoice(_, { entity, meta }) {
        return SalaryRequest.downloadSalaryInvoice(entity.id, meta);
    },
    index({ commit }, filter) {
        SalaryRequest.index({
            ...filter,
            weekly: 'true'
        })
            .then((data) => {
                commit('SET_REPORTS', data);
            });
    },
    getOperations({ commit }) {
        OperationRequest.index().then(({ data }) => {
            commit('SET_OPERATIONS', data);
        });
    },
    getDrivers({ commit }, filter) {
        DriverRequest.index(filter)
                    .then(({ data }) => {
                        commit('SET_DRIVERS', data);
                    });
    },
    getBranches({ commit }) {
        BranchRequest.index()
                    .then(({ data }) => {
                        commit('SET_BRANCHES', data);
                    });
    }
}