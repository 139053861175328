import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const store = new Vuex.Store({
    state () {
      return {
        entities: {
          data: [],
          meta: {}
        },
        operations: [],
        drivers: [],
        branches: []
      }
    },
    mutations,
    actions,
    getters,
});

export default store;