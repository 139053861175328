import BaseRequest from './base_request';

class SalaryRequest extends BaseRequest {

    download(data) {
        return this.performDownload('download', data);
    }

    downloadSalaryInvoice(id, data) {
        return this.performDownload(`${ id }/downloadSalaryInvoice`, data);
    }

}

export default new SalaryRequest('salary');